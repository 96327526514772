
// Libraries
import React from 'react'

// Components
import Seo from '../components/seo'
import Hero from '../components/hero'

export default function MaintenancePage() {
	return (
		<>
			<Seo title="Concerts for Carers Maintenance" />
			<Hero fullHeight bg="home" video bgText>
				<h1>Site Undergoing Maintenance</h1>
				<p>Apologies for the inconvenience, but we are currently making some changes to the website. Normal service should be resumed in around an hour.</p>
			</Hero>
		</>
	)
}
